import React, { useEffect, useState } from 'react'
import { statEndpoints } from 'src/api'
import { Wrapper } from './styles'
import { StatBusinessAggregates } from 'src/state/users'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons'

const AggregatedBusinessStats = () => {
  const [stats, setStats] = useState<StatBusinessAggregates>()

  useEffect(() => {
    statEndpoints.getBusinessAggregates().then(x => setStats(x))
  }, [])

  if (!stats) {
    return <p>Loading...</p>
  }

  return (
    <Wrapper>
      <div className="stat">
        <span style={{ whiteSpace: 'nowrap' }}>
          <FontAwesomeIcon icon={faCheck} /> Active Subscriptions
        </span>
        <div className="stat-value">{stats.totalActiveSubscriptions}</div>
      </div>
      <div className="stat">
        <span>Expired</span>
        <div className="stat-value">{stats.totalExpiredSubscriptions}</div>
      </div>
      <div className="stat">
        <span>Total</span>
        <div className="stat-value">{stats.totalActiveSubscriptions + stats.totalExpiredSubscriptions}</div>
      </div>
    </Wrapper>
  )
}

export default AggregatedBusinessStats
