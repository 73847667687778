import styled from 'styled-components/macro'

export const Modal = styled.div`
  position: fixed;
  z-index: 300;
  top: 12vh;
  left: 0;
  right: 0;
  margin: auto;
  width: 600px;
  background: ${props => props.theme.white};
  border: 1px solid ${props => props.theme.border};
  transition: opacity 0.25s ease-in-out;
  color: ${props => props.theme.normalText};

  border-radius: 8px;
  box-shadow: 0 4px 42px ${props => props.theme.shadow};
  overflow: hidden;

  @media (max-width: 768px) {
    width: min(1024px, calc(100vw - 32px)) !important;
    padding-top: 0;
  }

  &.closed {
    pointer-events: none;
    opacity: 0;
  }
`

export const Header = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 16px;

  width: 100%;
  background: ${props => props.theme.pageBackground};
`

export const ModalContent = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px;
  padding-bottom: 0;

  .crop-container {
    position: relative;
    width: 100%;
    height: 300px;
    border-radius: 8px;
    overflow: hidden;
    padding: 50px;

    .reactEasyCrop_CropArea {
      color: ${props => props.theme.backdrop};
    }
  }

  .controls {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 16px 0;

    svg {
      font-size: 22px;
      color: ${props => props.theme.accent};
      margin-top: -5px;
    }

    .slider-wrapper {
      width: 250px;
      margin-bottom: -4px;

      & > div {
        width: 250px;
        margin-left: 8px;
      }
    }
  }
`

export const ModalFooter = styled.div`
  border-top: 1px solid ${props => props.theme.border};
  padding: 12px;
  background: ${props => props.theme.pageBackground};
  display: flex;
  justify-content: space-around;
  align-items: center;

  button {
    margin-top: 0;
  }
`
