import styled from 'styled-components/macro'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  flex-wrap: wrap;

  @media (max-width: 768px) {
    width: 100%;
    justify-content: space-between;
    padding: 8px;

    .stat {
      flex: 1;
    }
  }

  .stat {
    background: ${props => props.theme.white};
    border-radius: 8px;
    padding: 8px 12px;

    span {
      font-size: 14px;
      font-weight: 500;
    }

    .stat-value {
      font-weight: 500;
      font-size: 30px;
      color: ${props => props.theme.accent};
    }

    svg {
      color: ${props => props.theme.green};
      font-size: 14px;
    }
  }
`
