import React, { useState, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Wrapper } from './styles'
import { patchCurrentUser } from 'src/state/users'
import { faCheck, faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import { useNavigate } from 'react-router-dom'
import Button from 'src/global/Button'
import { State } from 'src/state'
import { GoogleMap } from '@react-google-maps/api'
import { UserPatchType } from '@meniudigital/shared'
import { DEFAULT_LOGO_URL } from 'src/global/Header/TopRightDropdown'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const ROMANIA_COORDS = { lat: 45.9432, lng: 24.9668 }
const DEFAULT_ZOOM = 7

export default function SetLocation() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { user } = useSelector((state: State) => state.users)

  const [map, setMap] = useState<google.maps.Map | null>()

  const onLoad = useCallback((mapRef: google.maps.Map) => setMap(mapRef), [])
  const onUnmount = useCallback(() => setMap(null), [])

  if (!user) {
    return null
  }

  const hasCoords = Boolean(user?.coords?.latitude && user.coords.longitude)
  const initialCoords = hasCoords ? { lat: user.coords!.latitude, lng: user.coords!.longitude } : ROMANIA_COORDS

  const onClick = (e: google.maps.MapMouseEvent) => {
    map?.panTo(e.latLng?.toJSON()!)
  }

  const save = async () => {
    const zoomValue = map?.getZoom()
    const chosenCoords = map?.getCenter()?.toJSON()

    if ((zoomValue || 0) < 17) {
      window.showConfirm({
        text: `Locația aleasă nu este destul de exactă. Folosește funcționalitatea de zoom pentru a plasa indicatorul cât mai exact pe localul tău.`,
      })
      return
    }

    if (!chosenCoords?.lat || !chosenCoords?.lng) {
      window.showConfirm({
        text: `Locația aleasă nu este validă. Te rog încearcă din nou.`,
      })
      return
    }

    await dispatch(
      patchCurrentUser({
        type: UserPatchType.Location,
        data: { latitude: chosenCoords?.lat, longitude: chosenCoords?.lng, isVisible: user.coords?.isVisible || false },
      })
    )
    await window.showConfirm({ text: `Locația restaurantului a fost salvată cu succes.` })

    navigate(-1)
  }

  const isOrderGuard = new URLSearchParams(window.location.search).get('isOrderGuard')

  return (
    <Wrapper>
      <div className="page">
        {isOrderGuard ? (
          <h3>
            <FontAwesomeIcon icon={faInfoCircle} />
            Înainte de a folosi funcționalitatea de comandă, asigură-te că ai setat coordonatele exacte ale restaurantului tău.
          </h3>
        ) : (
          <h3>Unde se află restaurantul tău?</h3>
        )}
        <span className="explainer">Plasează indicatorul pe locația acestuia.</span>
        <GoogleMap
          mapContainerClassName="map-container"
          center={initialCoords}
          zoom={hasCoords ? 18 : DEFAULT_ZOOM}
          onLoad={onLoad}
          onClick={onClick}
          onUnmount={onUnmount}
          options={{
            mapTypeControl: false,
            fullscreenControl: false,
          }}
        >
          <div className="center-marker-wrapper">
            <div className="center-marker">
              <img loading="lazy" src={user.logoUrl || DEFAULT_LOGO_URL} alt="Restaurant logo" className="client-logo" />
            </div>
          </div>
        </GoogleMap>

        <span className="explainer" style={{ marginTop: 24, marginBottom: 16 }}>
          <FontAwesomeIcon icon={faInfoCircle} />
          Coordonatele restaurantului tău vor fi folosite și pentru validarea procesului de comandă, așa că plasează vârful
          indicatorului cât mai exact pe locația restaurantului tău.
        </span>

        <Button className="continue-button" text="Salvează" onClick={save} icon={faCheck} />
      </div>
    </Wrapper>
  )
}
