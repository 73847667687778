import React, { useEffect, useRef, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  Panel,
  PanelHeader,
  CategoryTitle,
  Button,
  CategoryActions as CategoryInfo,
  CountTag,
  DragHandler,
  NameAndDescriptionWrapper,
} from '../styles'
import Product from '../Product'
import { faCaretRight, faEyeSlash, faGripHorizontal, faPencilAlt, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons'
// @ts-ignore
import { Draggable, Droppable } from 'react-beautiful-dnd'
import { isBusiness, LanguageCode, MenuCategory, MenuProduct, User } from '@meniudigital/shared'
import { useSelector } from 'react-redux'
import { Wrapper } from './styles'
import { productsSelector } from 'src/state/selectors'
import { userEndpoints } from 'src/api'
import { useNavigate } from 'react-router-dom'
import { Screen } from 'src/screens'

type Props = {
  category: MenuCategory
  isFirst: boolean
  user: User
  isUnconfirmed: boolean
  inJiggleMode: boolean
  languageCode: LanguageCode
  query: string
  provided: any
  randomProp: number
  onRemoveProductPress: (product: MenuProduct) => void
  onRemoveCategoryPress: (category: MenuCategory) => void
  enterJiggleMode: () => void
}

const MAX_PRODUCTS_FOR_UNCONFIRMED = 10
const MAX_PRODUCTS_FOR_FREE = 300

export default function Category(props: Props) {
  const [isExpanded, setIsExpanded] = useState(false)
  const { list: products } = useSelector(productsSelector)

  const navigate = useNavigate()

  useEffect(() => {
    if (!window.openCategory) {
      window.openCategory = {}
    }
    window.openCategory[props.category.id] = () => setIsExpanded(true)
  }, [])

  const longPressTimeout = useRef<NodeJS.Timeout>()

  const sortProducts = (a: MenuProduct, b: MenuProduct) => a.index - b.index
  const filterProducts = (x: MenuProduct) =>
    x.categoryId === props.category.id &&
    x.names[props.languageCode]!.toLowerCase()
      .normalize('NFKD')
      .replace(/[^\w]/g, '')
      .includes(props.query.toLowerCase().normalize('NFKD').replace(/[^\w]/g, ''))

  const getProducts = () => products.filter(filterProducts).sort(sortProducts)

  const startTimer = () => {
    longPressTimeout.current = setTimeout(() => {
      props.enterJiggleMode()
    }, 500)
  }

  const toggle = () => {
    if (!props.inJiggleMode) {
      setIsExpanded(!isExpanded)
    }
  }

  const clearTimeout = () => {
    window.clearTimeout(longPressTimeout.current!)
  }

  const addProduct = async (e: any) => {
    e.stopPropagation()
    if (isUnconfirmed && products.length >= MAX_PRODUCTS_FOR_UNCONFIRMED) {
      window.showConfirm({
        title: `Funcționalitate limitată`,
        textComponent: (
          <span>
            Ai atins numărul maxim de produse. <br />
            <br />
            Confirmă adresa de e-mail pentru a adăuga până la {MAX_PRODUCTS_FOR_FREE} de produse în meniul tău.
          </span>
        ),
      })
      return
    }
    if (!isBusiness(user) && products.length >= MAX_PRODUCTS_FOR_FREE) {
      setTimeout(() => {
        userEndpoints.registerUserAction({ actionType: 'IsShownMaximumFreeProducts', data: {} })
      }, 0)
      await window.showConfirm({
        title: `Limită număr produse atinse`,
        textComponent: (
          <span>
            Ai atins numărul maxim de produse care pot fi adăugate în varianta gratuită (<strong>{MAX_PRODUCTS_FOR_FREE}</strong>
            ). <br />
            <br />
            Achiziționează pachetul <strong>MeniuDigital Business™</strong> pentru a adăuga un număr nelimitat de produse.
          </span>
        ),
      })
      navigate(Screen.MySubscription)
      return
    }
    window.openProductModal(category.id)
  }

  const {
    category,
    query,
    provided,
    onRemoveProductPress: removeProductConfirm,
    onRemoveCategoryPress: removeCategoryConfirm,
    inJiggleMode,
    languageCode,
    isUnconfirmed,
    user,
  } = props

  const isVisiblyExpanded = Boolean((isExpanded || query.length) && !inJiggleMode)
  const hasProducts = getProducts().length > 0
  const showsAddProductLabel = !products.find(x => x.categoryId === category.id) && !inJiggleMode

  if (query.length > 1 && !getProducts().length) {
    return null
  }

  return (
    <Wrapper>
      <Panel>
        <Droppable isDropDisabled={inJiggleMode} droppableId={'productsContainer' + category.id}>
          {(providedInnerContext, snapshot) => (
            <div
              {...providedInnerContext.droppableProps}
              ref={providedInnerContext.innerRef}
              style={{ background: snapshot.isDraggingOver ? '#11b7f322' : '', height: '100%' }}
            >
              <PanelHeader
                onMouseDown={startTimer}
                onTouchStart={startTimer}
                onTouchEnd={clearTimeout}
                onMouseUp={clearTimeout}
                onTouchMove={clearTimeout}
                onClick={toggle}
                style={{ cursor: inJiggleMode ? 'move' : 'pointer' }}
              >
                <CategoryInfo>
                  <CategoryTitle>
                    <DragHandler style={{ width: inJiggleMode ? '24px' : 0 }}>
                      <FontAwesomeIcon color="#818e99" icon={faGripHorizontal} style={{ marginRight: '8px' }} />
                    </DragHandler>
                    <CountTag style={{ filter: getProducts().length === 0 ? 'grayscale()' : '' }}>
                      {getProducts().length}
                    </CountTag>
                    <NameAndDescriptionWrapper>
                      <div>
                        <span>
                          {category.names[languageCode]}
                          {category.isHidden && <FontAwesomeIcon icon={faEyeSlash} />}
                        </span>
                      </div>
                      {category.descriptions[languageCode] && (
                        <div className="category-description">
                          <div>{category.descriptions[languageCode]}</div>
                        </div>
                      )}
                    </NameAndDescriptionWrapper>
                  </CategoryTitle>
                  {!inJiggleMode && (
                    <FontAwesomeIcon
                      style={{
                        transform: `rotate(${isVisiblyExpanded ? '90' : '0'}deg)`,
                        transition: 'transform 0.2s ease-in-out',
                        opacity: hasProducts ? 1 : 0,
                      }}
                      color="#818e99"
                      icon={faCaretRight}
                    />
                  )}
                </CategoryInfo>
                <div style={{ display: 'flex', marginRight: '-17px' }}>
                  <div className="expanding-div" style={{ width: isVisiblyExpanded ? '38px' : '0px' }}>
                    <Button title="Editează categoria" onClick={() => window.openCategoryModal(category)}>
                      <FontAwesomeIcon style={{ margin: '0 -1px' }} icon={faPencilAlt} />
                    </Button>
                  </div>

                  <Button
                    style={{
                      marginRight: '16px',
                      transition: 'opacity 0.25s ease-in-out',
                      opacity: inJiggleMode ? 0 : 1,
                      pointerEvents: inJiggleMode ? 'none' : 'all',
                    }}
                    className="green"
                    title="Adaugă produs"
                    onClick={addProduct}
                  >
                    {showsAddProductLabel && 'Adaugă produs'}
                    <FontAwesomeIcon icon={faPlus} style={{ marginRight: '0', marginLeft: showsAddProductLabel ? '8px' : '0' }} />
                  </Button>

                  <div
                    style={{
                      display: 'flex',
                      width: inJiggleMode ? '100px' : '1px',
                      overflow: 'hidden',
                      transition: 'width 0.35s ease-in-out',
                    }}
                  >
                    <Button
                      title="Editează categoria"
                      onClick={() => window.openCategoryModal(category)}
                      style={{ marginRight: '8px' }}
                    >
                      <FontAwesomeIcon style={{ margin: '0 -1px' }} icon={faPencilAlt} />
                    </Button>
                    <Button
                      title="Șterge categoria"
                      className="destructive"
                      onClick={() => removeCategoryConfirm(category)}
                      style={{ marginRight: '15px' }}
                    >
                      <FontAwesomeIcon icon={faTrash} />
                    </Button>
                  </div>
                </div>
              </PanelHeader>
              {isVisiblyExpanded &&
                getProducts().map((product, i, a) => (
                  <Draggable
                    key={product.id}
                    {...providedInnerContext.droppableProps}
                    draggableId={product.id}
                    index={product.index}
                  >
                    {providedProductsContext => (
                      <div
                        style={{ pointerEvents: isVisiblyExpanded ? 'auto' : 'none' }}
                        ref={providedProductsContext.innerRef}
                        {...providedProductsContext.draggableProps}
                        {...providedProductsContext.dragHandleProps}
                      >
                        <div
                          className={
                            'product-wrapper' +
                            (isVisiblyExpanded ? '' : ' delayed-hidden') +
                            (i === a.length - 1 ? ' last-in-category' : '')
                          }
                        >
                          <Product
                            isFirst={i === 0}
                            query={query}
                            user={user}
                            product={product}
                            openEditModal={(isClone?: boolean) => window.openProductModal(product, isClone)}
                            removeProduct={() => removeProductConfirm(product)}
                            languageCode={languageCode}
                          />
                        </div>
                      </div>
                    )}
                  </Draggable>
                ))}
              {providedInnerContext.placeholder}
            </div>
          )}
        </Droppable>

        {provided.placeholder}
      </Panel>
    </Wrapper>
  )
}
