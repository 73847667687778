import styled from 'styled-components/macro'

export const Wrapper = styled.div`
  .action {
    display: flex;
    background-color: ${props => props.theme.white};
    border-radius: 14px;
    padding: 8px;
    justify-content: space-between;
    flex-direction: row;
    z-index: 2;
    font-weight: 500;
    border: 1px solid ${props => props.theme.border};
  }

  img {
    width: 16px;
  }

  .table {
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: center;
    padding-top: 24px;
    border: 2px solid ${props => props.theme.orange};
    background-color: ${props => props.theme.orange} + '33';
    border-radius: 14px;
    border-bottom-right-radius: 0;
    font-size: 42px;
    width: 100px;
    align-items: center;
    margin: -8px 0;
    margin-right: -8px;
    margin-left: 24px;
  }

  .order-table {
    border-color: ${props => props.theme.blue};
    background-color: ${props => props.theme.blue}44;
    padding-bottom: 8px;
    min-height: 92px;
  }

  .preorder-table {
    border-color: ${props => props.theme.teal};
    background-color: ${props => props.theme.teal}44;
    overflow: hidden;
  }

  .order-number {
    background-color: ${props => props.theme.blue};
    width: 96px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    position: absolute;
    left: 0;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1px 0;
  }

  .order-number-text {
    font-size: 15px;
    font-weight: 600;
    color: ${props => props.theme.white};
  }

  .preorder-number {
    background-color: ${props => props.theme.teal};
  }

  .consumer-name-text {
    font-size: 22px;
    font-weight: 600;
    text-align: center;
    color: ${props => props.theme.teal};
    padding: 0 6px;
    margin: 0;
    line-height: 22px;
  }

  .table-text {
    font-size: 14px;
    margin-top: 4px;
    color: ${props => props.theme.orange};
  }

  .order-table-text {
    font-weight: 600;
    color: ${props => props.theme.blue};
  }

  .table-number {
    font-weight: 700;
    font-size: 32px;
    color: ${props => props.theme.orange};
    margin-top: -4px;
  }

  .order-table-number {
    color: ${props => props.theme.blue};
  }

  .device-type {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 6px;

    img {
      width: 14px;
    }
  }

  .device-type-text {
    font-size: 14px;
  }

  .left-section {
    justify-content: center;
    padding-left: 4px;
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  .action-title {
    font-size: 17px;
    color: ${props => props.theme.orange};
    font-weight: 600;
  }

  .when {
    font-size: 14px;
  }

  .bullet {
    width: 7px;
    height: 7px;
    background-color: #9ae963;
    border-radius: 7px;
    margin-left: 3px;
    margin-right: 5px;
  }

  .information-row {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-radius: 8px;
    padding: 8px 12px;
    padding-right: 24px;
    margin-left: 0;
    background-color: ${props => props.theme.green};
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: -8px;
    padding-top: 16px;

    span {
      word-break: break-all;
    }

    &.white {
      border-top: 1px solid ${props => props.theme.border};
      border-top-right-radius: 0;
      border-top-left-radius: 0;
      /* margin-top: 8px; */
      z-index: 0;
    }

    .fa-check,
    svg {
      color: ${props => props.theme.white};
      margin-right: 8px;
    }

    &.client-info {
      display: flex;
      flex-direction: row;
      gap: 8px;
      padding: 4px 8px;
      padding-right: 16px;
      border-radius: 24px;
      padding-bottom: 24px;
      margin-bottom: -24px;
      /* border-bottom-right-radius: 0;
      border-bottom-left-radius: 0; */
      background: ${props => props.theme.white};
      display: inline-flex;
      font-weight: 600;
      border-bottom: 1px solid ${props => props.theme.border};
      font-size: 14px;
      cursor: pointer;

      &:hover {
        background-color: ${props => props.theme.blue}66;
      }

      img {
        width: 30px;
        border-radius: 50%;
      }

      span {
        margin-bottom: 16px;
      }
    }
  }

  .with-continuation {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    box-shadow: 0px 8px 12px 0px rgba(0, 0, 0, 0.1);
    background-color: ${props => props.theme.white};
    z-index: 2;
    position: relative;
  }

  .extra-comments-text {
    font-size: 15px;
    color: ${props => props.theme.red};
    text-shadow: 0px 0px 3px #fff;
    font-weight: 600;
  }

  .confirmed-text {
    font-size: 14px;
    font-weight: 600;
    color: ${props => props.theme.white};
    padding-right: 4px;
  }

  .total-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 16px;
    padding: 16px;
    padding-bottom: 4px;
    border-top: 1px solid ${props => props.theme.border};
    font-size: 17px;
    font-weight: 700;

    .currency {
      font-weight: 400;
      font-size: 14px;
      text-transform: uppercase;
    }
  }

  .actions-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0px 16px;
  }

  .center-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 6px;
  }

  .products-wrapper {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    padding-top: 24px;
    padding-bottom: 8px;
    background-color: ${props => props.theme.white};
    margin-top: -16px;
    border-bottom-width: 1px;
    border-bottom-color: ${props => props.theme.border};
    z-index: 1;
    width: min(400px, 100vw - 32px);
  }

  .total-price-text {
    font-weight: 700;
    font-size: 17px;
  }

  .call-icon {
    position: absolute;
    bottom: 4px;
    right: 4px;
    transform: scaleX(-1);
  }

  .boldish {
    font-weight: 600;
  }

  .light {
    font-weight: 500;
  }

  .bold {
    font-weight: 800;
  }
`
