import {
  ConsumerOrder,
  ConsumerOrderConsumptionPlace,
  ConsumerOrderRequestPlace,
  User,
  WaiterCall,
  computeConsumerOrderPrice,
} from '@meniudigital/shared'
import moment from 'moment'
import React from 'react'
// import OrderProductList from './OrderProductList'

import { Wrapper } from './styles'
import OrderProductList from './OrderProductList'
import { useSelector } from 'react-redux'
import { usersSelector } from 'src/state/selectors'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons'

type Props = {
  action: (WaiterCall | ConsumerOrder) & { user: User }
}

export const START_OF_TODAY = +moment().startOf('day')

export default function OrderOrWaiterCall({ action }: Props) {
  const isFromToday = action.timestamp >= START_OF_TODAY
  const isConfirmed = Boolean(action.waiterResponse)

  const order = action as ConsumerOrder & { user: User }

  const isWaiterCall = typeof order.products === 'undefined'
  const isOrder = typeof order.products !== 'undefined'
  const isPreorder = isOrder && order.request?.place === ConsumerOrderRequestPlace.Remote
  const isExpired = !isConfirmed && !isFromToday
  const hasBody = isOrder || isConfirmed || isExpired
  const { consumer, request, consumption } = action as ConsumerOrder
  const { user: { isSuperAdmin } = {} } = useSelector(usersSelector)

  const getActionTitle = () => {
    if (isOrder) {
      if (isConfirmed || isExpired) {
        return isPreorder ? 'Precomandă' : 'Comandă la masă'
      } else {
        return isPreorder ? 'Precomandă nouă' : 'Comandă nouă (la masă)'
      }
    } else {
      return 'Un ospătar a fost chemat'
    }
  }

  const isOnLocation = consumption?.place === ConsumerOrderConsumptionPlace.OnLocation
  const isIphone = consumer.deviceType.toLowerCase().includes('iphone') || consumer.deviceType.toLowerCase().includes('apple')

  const mainColor = isPreorder ? '#32bd98' : isOrder ? '#11b7f3' : '#ff8324'

  return (
    <Wrapper>
      {isSuperAdmin && (
        <div
          className="information-row client-info"
          onClick={() => window.open(`${process.env.REACT_APP_MENU_BASE_URL}/${order.user.username}`)}
        >
          <img loading="lazy" src={order.user.logoUrl} alt="User logo" />
          <div>
            <div className="boldish">{order.user.name}</div>
            <div className="light">/{order.user.username}</div>
          </div>
        </div>
      )}
      <div className={`action ${hasBody ? 'with-continuation' : ''}`}>
        <div className="left-section">
          <div className="center-row">
            <img
              src={isPreorder ? '/icons/bell-preorder.png' : isOrder ? '/icons/bell-order.png' : '/icons/bell.png'}
              alt="Order icon"
            />
            <p className="action-title" style={{ color: mainColor }}>
              {getActionTitle()}
            </p>
          </div>
          <div className="center-row">
            {!isConfirmed && <div className="bullet" />}
            <p className="when">
              acum {moment(action.timestamp).fromNow(true)} (la {moment(action.timestamp).format('HH:mm')})
            </p>
          </div>
          {isWaiterCall || request?.place === ConsumerOrderRequestPlace.OnLocation ? (
            <div className="device-type">
              <img src={isIphone ? '/icons/apple.png' : '/icons/android.png'} alt="Device type icon" />
              <p className="device-type-text">{isIphone ? consumer.deviceType : consumer.deviceName}</p>
            </div>
          ) : (
            <div className="device-type">
              <img src={isOnLocation ? '/icons/in-restaurant.png' : '/icons/food-pickup.png'} alt="Device type icon" />
              <p className="device-type-text">{isOnLocation ? 'Consumație în restaurant' : 'La pachet (pickup)'}</p>
            </div>
          )}
        </div>
        {isPreorder ? (
          <div className="table order-table preorder-table">
            <div className="order-number preorder-number">
              <p className="order-number-text">{order.orderNumber?.slice(0, 4)}</p>
            </div>
            <p className="consumer-name-text">{order.consumer.name}</p>
          </div>
        ) : isOrder ? (
          <div className="table order-table">
            <div className="order-number">
              <p className="order-number-text">{order.orderNumber?.slice(0, 4)}</p>
            </div>
            <p className="table-text order-table-text">Masa</p>
            <p className="table-number order-table-number">{order.consumption?.tableNumber || action.tableNumber}</p>
          </div>
        ) : (
          <div className="table">
            <p className="table-text">Masa</p>
            <p className="table-number">{(action as WaiterCall).tableNumber}</p>
          </div>
        )}
      </div>

      {Boolean(order.products?.length) && (
        <div className={`products-wrapper ${isConfirmed || isExpired || order.extraComments ? 'with-continuation' : ''}`}>
          <OrderProductList products={order.products} currencyCode={order.currencyCode} />
          <div className="total-row">
            <span className="total-text">Total</span>
            <span>
              {computeConsumerOrderPrice({ products: order.products })}

              <span className="currency"> {order.currencyCode}</span>
            </span>
          </div>
        </div>
      )}
      {Boolean(order.extraComments?.length) && (
        <div className={`information-row white ${isConfirmed || isExpired ? 'with-continuation' : ''}`}>
          <span className="extra-comments-text">💬 "{order.extraComments}"</span>
        </div>
      )}
      {isConfirmed && !order.finalized && (
        <div className="information-row">
          <FontAwesomeIcon icon={faCheck} />
          <span className="confirmed-text">
            Confirmat
            {isOrder ? 'ă' : ''} de
            <span className="bold white"> {action.waiterResponse?.waiterName} </span>
            acum {moment(action.waiterResponse!.timestamp).fromNow(true)}{' '}
          </span>
        </div>
      )}
      {order.finalized && (
        <div className="information-row">
          <FontAwesomeIcon icon={faCheck} />
          <span className="confirmed-text">
            Finalizată de
            <span className="bold"> {order.finalized.byWaiterName} </span>
            acum {moment(order.finalized.at).fromNow(true)}{' '}
          </span>
        </div>
      )}
      {!isConfirmed && !isFromToday && (
        <div className="information-row ios-disabled">
          <FontAwesomeIcon icon={faCheck} />
          <span className="confirmed-text">
            Neconfirmat{isOrder ? 'ă' : ''} & Expirat{isOrder ? 'ă' : ''}
          </span>
        </div>
      )}
    </Wrapper>
  )
}
