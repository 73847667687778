import { StatNewRegistrationsAggregates, StatBusinessAggregates, StatDailyBusinessPurchase } from 'src/state/users'
import {
  BusinessOrder,
  MenuProduct,
  BusinessOrderIntent,
  BillingInfo,
  CreateAccountRequestBody,
  LanguageCode,
  BusinessLink,
  ProductSuggestion,
  ActionLog,
  User,
  UserPatchBody,
  UserDevice,
  MenuCategory,
  FeatureVote,
  ConsumerOrderViewModel,
  ProductPatchBody,
  WaiterCall,
  ConsumerReservation,
  ConsumerReservationPatchBody,
} from '@meniudigital/shared'
import methods from './methods'

export const categoryEndpoints = {
  create: (category: Object): Promise<MenuCategory> => methods.post('categories', category),
  update: (category: Object): Promise<MenuCategory> => methods.put(`categories/${(category as any).id}`, category),
  getAll: (): Promise<MenuCategory[]> => methods.get('categories'),
  move: (moveInfo: { categoryId: string; destinationIndex: number }): Promise<MenuCategory[]> =>
    methods.post('categories/move', moveInfo),
  remove: (categoryId: string): Promise<MenuCategory> => methods.delete(`categories/${categoryId}`),
}

export const productEndpoints = {
  create: (productData: FormData): Promise<MenuProduct> => methods.post('products', productData),
  edit: (productData: FormData): Promise<MenuProduct> =>
    methods.put(`products/${JSON.parse(productData.get('productJson') as string).id}`, productData),
  getAll: (): Promise<MenuProduct[]> => methods.get('products'),
  move: (moveInfo: { productId: string; destinationIndex: number }): Promise<MenuProduct[]> =>
    methods.post('products/move', moveInfo),
  transfer: (transferInfo: {
    productId: string
    destinationCategoryId: string
    destinationIndex: number
  }): Promise<MenuProduct[]> => methods.post('products/transfer', transferInfo),
  remove: (productId: string): Promise<MenuProduct> => methods.delete(`products/${productId}`),
  computeNutritionalDeclaration: (name: string, description: string): Promise<string> =>
    methods.get(
      `compute-nutritional-declaration?name=${encodeURIComponent(name)}&description=${encodeURIComponent(description)}`
    ),
  patch: (patchInfo: ProductPatchBody & { productId: string }): Promise<MenuProduct> =>
    methods.patch(`products/${patchInfo.productId}`, patchInfo),
}

export const businessOrderEndpoints = {
  create: <T>(order: BusinessOrderIntent): Promise<T> => methods.post('holder-orders', order),
  activate: (id: string): Promise<BusinessOrder> => methods.post(`holder-orders/${id}/activate`, {}),
  getById: (id: string): Promise<BusinessOrder> => methods.get(`holder-orders/${id}`),
  getAll: (): Promise<(BusinessOrder & { user: User })[]> => methods.get(`holder-orders`),
  getMostRecent: (): Promise<BusinessOrder> => methods.get('most-recent-order'),
  getCompanyBillingInfo: (cui: string): Promise<BusinessOrder['billingInfo']> => methods.get(`company-billing-info?cui=${cui}`),
  confirmBankPaymentFor: (orderId: string): Promise<void> => methods.post(`/holder-orders/${orderId}/confirm-bank-payment`, {}),
  confirmDeliveryFor: ({ orderId, awb }: { orderId: string; awb: string }): Promise<void> =>
    methods.post(`holder-orders/${orderId}/confirm-delivery`, { awb }),
  cancelOrder: (orderId: string): Promise<void> => methods.delete(`/holder-orders/${orderId}/cancel`),
}

export const consumerOrderEndpoints = {
  getAll: (): Promise<(ConsumerOrderViewModel & { user: User })[]> => methods.get(`consumer-orders`),
}

export const waiterCallEndpoints = {
  getAll: (): Promise<(WaiterCall & { user: User })[]> => methods.get(`waiter-calls`),
}

export const reservationEndpoints = {
  getAll: (): Promise<(ConsumerReservation & { user: User })[]> => methods.get(`consumer-reservations`),
  patch: (patchInfo: ConsumerReservationPatchBody & { reservationId: string }): Promise<ConsumerReservation> =>
    methods.patch(`consumer-reservations/${patchInfo.reservationId}`, patchInfo),
  remove: (reservationId: string): Promise<ConsumerReservation> => methods.delete(`consumer-reservations/${reservationId}`),
}

type GetUsersQuery = {
  pageNumber: number
  searchQuery: string
  onlyActive: boolean
  onlyBusiness: boolean
}

export const userEndpoints = {
  validateUsername: (username: string): Promise<{ isAvailable: boolean }> =>
    methods.get('validate-username?username=' + username),
  createAccountAndLogin: (accountInfo: CreateAccountRequestBody): Promise<string> => methods.post(`users`, accountInfo),
  confirmEmail: (registrationRequestId: string): Promise<{ successfullyConfirmed: boolean; hasNoAccountYet: boolean }> =>
    methods.post(`confirm-email`, { registrationRequestId }),

  login: (loginInfo: { username: string; password: string }): Promise<string> => methods.post('login', loginInfo),
  sendResetPasswordEmail: (email: string): Promise<string> => methods.post(`reset-password`, { email }),
  changePassword: (passwords: Object) => methods.post('change-password', passwords),
  setNewPassword: (passwordsAndToken: Object) => methods.post('set-new-password', passwordsAndToken),
  getAll: ({ pageNumber, searchQuery, onlyActive, onlyBusiness }: GetUsersQuery) =>
    methods.get(
      `users?pageNumber=${pageNumber}&searchQuery=${searchQuery}&onlyActive=${onlyActive}&onlyBusiness=${onlyBusiness}`
    ),
  getCurrentUser: () => {
    if (!localStorage.token) {
      throw new Error('Attempting to GET /me without localStorage.token')
    }

    return methods.get(`me`)
  },
  updateCurrentUser: (updatedFields: Partial<User>): Promise<User> => methods.put('me', updatedFields),
  patchCurrentUser: (patchInfo: UserPatchBody): Promise<User> => methods.patch('me', patchInfo),
  patchUser: (patchInfo: UserPatchBody & { userId: string }): Promise<User> =>
    methods.patch(`users/${patchInfo.userId}`, patchInfo),
  getBillingInfo: (): Promise<BillingInfo> => methods.get(`me/billing-info`),
  setLogo: (formData: Object) => methods.post('me/set-logo', formData),
  setCoverPhoto: (formData: Object) => methods.post('me/set-cover-photo', formData),
  getPasswordResetRequest: (resetRequestId: string): Promise<{ username: 'string' }> =>
    methods.get(`reset-requests/${resetRequestId}`),
  setMenuLanguages: (languages: LanguageCode[]) => methods.post(`me/set-menu-languages`, { languages }),
  setBusinessLink: (link: BusinessLink) => methods.post(`me/set-business-medium`, { businessMedium: link }),
  setProductSuggestion: (productSuggestion: ProductSuggestion) =>
    methods.post(`me/set-product-suggestion`, { productSuggestion }),
  registerUserAction: (action: { actionType: string; data: Object }) => methods.post(`me/user-action`, action),
  getMyStats: () => methods.get(`me/stats`),
  resendActivationEmail: (): Promise<{}> => methods.post(`me/resend-activation-email`, {}),
  removeDevice: (deviceId: string): Promise<UserDevice> => methods.delete(`devices/${deviceId}`),
  impersonateUser: (userId: string): Promise<string> => methods.post(`users/${userId}/impersonate`, {}),
}

export const registrationRequestsEndpoints = {
  getById: (registrationRequestId: string): Promise<{ email: 'string' }> =>
    methods.get(`registration-requests/${registrationRequestId}`),
}

export const actionLogEndpoints = {
  getAll: (query: string): Promise<ActionLog[]> => methods.get(`action-logs/${query}`),
}
export const statEndpoints = {
  getRegistrationsAggregate: (): Promise<StatNewRegistrationsAggregates> => methods.get(`registration-stats`),
  getBusinessAggregates: (): Promise<StatBusinessAggregates> => methods.get(`stats/business-aggregates`),
  getBusinessPurchasesGraphDaily: (): Promise<StatDailyBusinessPurchase[]> => methods.get(`stats/business-purchases-graph-daily`),
}

type ChartPointData = {
  label: string
  visitCount: number
  uniqueUserCount: number
}

type UserCharPointData = {
  label: string
  count: number
}

export type NavigationStatsObject = {
  topMenuVisitsByUserLast7Days: ChartPointData[]
  totalVisitsPerDay: ChartPointData[]
  userSummary: UserCharPointData[]
}
export const navigationLogsEndpoints = {
  getAll: (): Promise<NavigationStatsObject> => methods.get(`navigation-stats`),
}

export type FeatureVoteStat = {
  label: string
  value: number
}

export const featureVotesEndpoints = {
  create: (vote: { questionId: string; answers: string[]; extraSuggestion?: string }) => methods.post('feature-votes', vote),
  getAll: (): Promise<FeatureVote[]> => methods.get('feature-votes'),
  getFeatureVoteStats: (): Promise<FeatureVoteStat[]> => methods.get('feature-vote-stats'),
}
