import styled from 'styled-components/macro'
//@ts-ignore
import Toggle from 'react-toggle'
import Textarea from 'src/global/form/Textarea'

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  min-height: calc(100vh - 161px);
  padding-top: 24px;

  .product-variant {
    padding-left: 8px;
    font-size: 13px;
  }

  .empty-placeholder-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 50px 0;
    text-align: center;
  }

  .fa-plus-mdc {
    color: ${props => props.theme.accent};
    font-size: 32px;
  }

  .expires-banner {
    padding: 8px;
    width: calc(100vw - 16px);
    background-color: ${props => props.theme.accent};
    justify-content: center;
    display: flex;
    flex-wrap: wrap;
    margin-top: -24px;
    margin-bottom: 24px;
    color: ${props => props.theme.white};
    font-weight: 500;
    box-shadow: inset 0 3px 10px ${props => props.theme.shadow};

    button {
      color: ${props => props.theme.white};
      text-decoration: underline;
      margin-top: 0;
      height: auto;
      padding: 0 8px;

      @media (max-width: 768px) {
        padding: 8px 16px;
      }

      :hover {
        text-decoration: none;
      }
    }
  }

  .top-bar {
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: all 0.5s cubic-bezier(0.25, 1, 0.32, 1);
    position: sticky;
    top: 58px;
    z-index: 10;
    background: ${props => props.theme.pageBackground};
    width: min(calc(100vw - 16px), 736px);
    padding-right: 8px;
    padding-left: 61px;
    margin-top: -24px;
    padding-top: 24px;
    padding-bottom: 16px;

    @media (max-width: 768px) {
      padding-top: 16px;
      padding-bottom: 16px;

      .search-input-wrapper {
        top: 16px;
      }

      .section-toggle {
        margin-left: -54px;
        margin-bottom: 0px;
      }
    }

    button {
      margin-top: 0;
      border-color: ${props => props.theme.white};
      float: left;

      @media (max-width: 375px) {
        padding-left: 12px;
        padding-right: 12px;
      }

      :hover {
        border-color: ${props => props.theme.accent};
      }
    }
  }

  .info-prompt {
    position: fixed;
    right: 16px;
    bottom: 16px;
    background: ${props => props.theme.white};
    border-radius: 8px;
    width: 240px;
    padding: 16px;
    padding-right: 32px;
    padding-left: 32px;
    font-size: 14px;
    box-shadow: -4px 0 12px ${props => props.theme.shadow};
    overflow: hidden;
    z-index: 100;
    color: ${props => props.theme.normalText};

    svg {
      color: ${props => props.theme.mutedText};
    }

    .emoji {
      position: absolute;
      left: -55px;
      top: -65px;
      font-size: 120px;
      opacity: 0.3;
      z-index: -1;
    }

    .close-button {
      top: 16px;
      right: 16px;
    }
  }

  .jiggle-mode-indicator {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 752px;
    padding: 0 16px;

    font-size: 14px;
    font-weight: 500;

    transition: all 0.3s ease-out;
    height: 0;
    overflow: hidden;
    border-radius: 24px;
    color: ${props => props.theme.normalText};

    position: sticky;
    top: 140px;
    z-index: 10;
    background: ${props => props.theme.white};
    /* box-shadow: -4px 0 12px ${props => props.theme.disabledButton}; */
    box-shadow: -4px 0 16px ${props => props.theme.darkerShadow};

    @media (max-width: 768px) {
      width: min(1024px, calc(100vw - 32px)) !important;
      margin-right: 0;
    }
  }
`

export const SmallDescription = styled.div`
  font-size: 13px;
  margin-top: 6px;
  color: ${props => props.theme.normalText};
`

export const Panel = styled.div`
  background-color: ${props => props.theme.white};
  border-radius: 8px;
  box-shadow: 0 4px 12px ${props => props.theme.shadow};
  overflow: hidden;
  color: ${props => props.theme.normalText};
  position: relative;
  width: 720px;

  @media (max-width: 768px) {
    width: min(1024px, calc(100vw - 32px)) !important;
    margin-right: 0;
  }

  .row {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 32px;
  }

  &.centered {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 50px 0;
    text-align: center;
    margin-bottom: 32px;

    .panel {
      margin-bottom: 0 !important;
    }
  }

  p {
    margin-top: 16px;
    margin-bottom: 24px;
    font-size: 18px;
    padding: 0 24px;
  }

  .delayed-hidden {
    animation: hide-delayed 0s linear;
    animation-fill-mode: forwards;
    animation-delay: 1s;
  }

  @keyframes hide-delayed {
    to {
      max-height: 0;
      height: 0;
      padding: 0;
      border: none;
      box-shadow: none;
      visibility: hidden;
    }
  }

  @media (max-width: 768px) {
    .delayed-hidden {
      animation: none;
      display: none;
    }
  }
`

export const PanelHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  padding: 16px;
  user-select: none;
`

export const Title = styled.span`
  color: #818e99;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 0.05em;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;
  margin-bottom: 12px;
`

export const OutOfStockIndicator = styled.div`
  text-transform: uppercase;
  font-size: 10px;
  font-weight: 800;
  display: inline-block;
  color: ${props => props.theme.white};
  background: #ff9800;
  padding: 4px 8px;
  border-radius: 23px;
  margin-top: 2px;
`

export const EditToggle = styled(Toggle)`
  position: absolute !important;
  top: 12px;
  right: 12px;

  &.react-toggle--focus .react-toggle-thumb {
    box-shadow: none !important;
  }

  &.react-toggle:hover:not(.react-toggle--disabled) .react-toggle-track {
    background-color: ${props => props.theme.placeholder}bb;
  }

  &.react-toggle .react-toggle-track {
    background-color: ${props => props.theme.placeholder};
  }

  &.react-toggle:not(.react-toggle--checked) .react-toggle-thumb {
    border-color: ${props => props.theme.placeholder};
  }

  &.react-toggle--checked .react-toggle-thumb {
    border-color: ${props => props.theme.accent};
  }

  &.react-toggle--checked .react-toggle-track {
    background-color: ${props => props.theme.accent};
  }

  &.react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track {
    background-color: ${props => props.theme.accent}bb;
  }
`

export const Button = styled.div`
  color: ${props => props.theme.blue};
  border-radius: 8px;
  padding: 8px 12px;
  cursor: pointer;
  white-space: nowrap;
  transition: all 0.15s ease-in-out;
  background: ${props => props.theme.blue}22;
  ${props => props.disabled && 'opacity: 0.4; pointer-events: none'};
  height: 36px;
  font-weight: 600;

  span {
    display: inline-block;
    width: 0;
    overflow: hidden;
    transition: all 0.3s ease-in-out;
    margin-bottom: -4px;
  }

  :hover {
    background: ${props => props.theme.blue}44;
  }

  &.destructive {
    color: ${props => props.theme.red};
    background: ${props => props.theme.red}22;

    :hover {
      background: ${props => props.theme.red}44;
    }
  }

  &.green {
    color: ${props => props.theme.accent};
    display: flex;
    align-items: center;
    margin-left: 16px;
    border-color: ${props => props.theme.white}00;
    padding-bottom: 9px;
    background: ${props => props.theme.accent}22;
    font-weight: 600;

    svg {
      margin-right: 8px;
      margin-bottom: -1px;
    }

    :hover {
      background: ${props => props.theme.accent}44!important;
    }
  }

  &.left {
    margin-right: 0;
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
  }

  &.right {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
    margin-left: -1px;
  }
`

export const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;

  margin: -12px 0;
  margin-left: 12px;
  margin-right: -12px;
  padding: 16px;
  padding-bottom: 2px;
  padding-right: 0;
  background: ${props => props.theme.activeBackground};
  cursor: default;

  div {
    display: inline-block;
    margin-right: 12px;
    margin-bottom: 12px;
  }

  @media (max-width: 768px) {
    flex-direction: column;
  }
`

export const DragIconWrapper = styled.div`
  margin-right: 16px;
  color: ${props => props.theme.border};
  border-radius: 8px;
  overflow: hidden;
  flex-shrink: 0;
`

export const CategoryTitle = styled.div`
  font-size: 14px;
  color: ${props => props.theme.normalText};
  margin-right: 8px;
  display: flex;
  align-items: center;
`

export const EditButton = styled.div`
  position: absolute;
  top: 8px;
  right: 8px;

  background: ${props => props.theme.white};
  color: ${props => props.theme.accent};
  border: 1px solid ${props => props.theme.accent};

  border-radius: 8px;
  cursor: pointer;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;

  &.active {
    background: ${props => props.theme.accent};
    color: ${props => props.theme.white};
  }
`

export const FormInput = styled.input`
  width: calc(((780px - (16px * 3)) / 2) - 2px - 8px);
  height: 41px;
  padding: 8px 12px;
  border-radius: 8px;
  margin-top: 8px;
  padding-right: 40px;

  &.small {
    width: 90px;
  }

  @media (max-width: 768px) {
    width: 100% !important;
  }
`

export const FormTextarea = styled(Textarea)`
  width: calc(((780px - (16px * 3)) / 2) - 2px - 8px);
  height: 41px;
  padding: 8px 12px;
  border-radius: 8px;
  margin-top: 8px;
  padding-right: 40px;

  &.small {
    width: 90px;
  }

  @media (max-width: 768px) {
    width: 100% !important;
  }
  resize: none;
  height: 160px;
`

export const SearchInput = styled.input`
  height: 43px;
  margin-top: 0 !important;
  padding: 8px 12px;
  border-radius: 8px;
  flex: 1;
  background-color: ${props => props.theme.white}!important;
`

export const Label = styled.span`
  font-size: 11px;
  color: ${props => props.theme.label};
  text-transform: uppercase;
  font-weight: 700;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-top: 16px;
`

export const FormInputWrapper = styled.div`
  display: inline-block;
  position: relative;
`

export const Suffix = styled.div`
  position: absolute;
  right: 12px;
  top: 14px;
  z-index: 15;
  font-size: 14px;
  pointer-events: none;
  color: ${props => props.theme.placeholder};
  height: 30px;
  display: flex;
  align-items: center;

  &.for-category {
    top: 44px;
  }
`

export const CategoryActions = styled.div`
  display: flex;
  align-items: center;
`

export const MatchedString = styled.span`
  color: ${props => props.theme.accent};
`
export const DragHandler = styled.div`
  display: inline-block;
  overflow: hidden;
  transition: width 0.25s ease-in-out;
`

export const CountTag = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 25px;
  height: 25px;
  margin-right: 8px;

  border-color: #fff0;
  border-radius: 100px;
  background: ${props => props.theme.accent}22;

  cursor: default;

  color: ${props => props.theme.accent};
  font-weight: 600;
  font-size: 12px;
`

export const CategoryEditInfo = styled.div`
  font-size: 14px;
  font-weight: 400;
  color: ${props => props.theme.placeholder};
  overflow: hidden;
`

export const LanguageButtons = styled.div`
  display: flex;
`
export const LanguageButton = styled.div`
  margin-left: 8px;
  display: flex;
  height: 32px;
  width: 36px;
  justify-content: center;
  align-items: center;

  border-radius: 8px;
  cursor: pointer;

  &.selected {
    background: ${props => props.theme.white};
    box-shadow: 0 4px 12px rgb(0 0 0 / 5%);
  }

  &:not(.selected):hover {
    background: ${props => props.theme.white}bb;
  }
`

export const LanguageAndInfoRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 720px;
  height: 32px;
  transition: height 0.25s ease-in-out;
  overflow: hidden;

  @media (max-width: 768px) {
    width: min(1024px, calc(100vw - 32px)) !important;
    margin-right: 0;
  }
`

export const NameAndDescriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  font-weight: 500;

  .category-description {
    color: ${props => props.theme.mutedText}bb;
    font-size: 12px;
    color: #818e99bb;
    height: 15px;
    margin-bottom: -15px;
    width: 20px;

    div {
      display: inline-block;
      max-width: min(420px, calc(100vw - 147px));
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  svg {
    color: ${props => props.theme.red};
    margin-left: 4px;
    width: 13px;
  }
`

export const Footer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  position: relative;
  align-items: center;
  font-size: 12px;
  box-shadow: 4px -4px 12px rgb(0 0 0 / 5%);
  color: ${props => props.theme.placeholder};
  width: 720px;
  margin: auto;
  margin-top: 57px;

  align-self: center;

  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  overflow: hidden;
  background: ${props => props.theme.white};

  > div {
    flex: 1;
    display: flex;
    align-items: center;

    &.center {
      justify-content: center;
    }
    &.start {
      justify-content: flex-start;
    }
    &.end {
      justify-content: flex-end;
      align-items: flex-end;
      flex-direction: column;

      a {
        margin-top: 1px;
        margin-right: 16px;
      }
    }
  }

  .netopia-banner {
    height: 45px;
    background: linear-gradient(to right, #fff, #fff, #fff, #fff, transparent);
  }

  .logo {
    height: 23px;
    margin-right: 16px;
  }

  a {
    color: ${props => props.theme.placeholder};
    text-decoration: none;
    font-weight: 400;

    :hover {
      color: ${props => props.theme.placeholder}99;
    }
  }

  @media (max-width: 768px) {
    width: min(1024px, calc(100vw - 32px)) !important;
    margin-right: 16px;
    margin-left: 16px;
    height: 35px;
    background: transparent;
    box-shadow: none;
    margin-top: 68px;
    font-size: 11px;

    .netopia-banner {
      height: 35px;
    }

    .logo {
      width: 0;
      margin-top: 2px;
    }

    .center {
      flex: 0;
      width: 0;
    }

    .end {
      margin-left: -4px;

      a {
        margin-right: 0px !important;
        white-space: nowrap;
        opacity: 0.7;
      }
    }
  }
`
