import { ConsumerReservation } from '@meniudigital/shared'
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { reservationEndpoints } from 'src/api'

type ApiResponseList = Awaited<ReturnType<typeof reservationEndpoints.getAll>>

export type State = {
  isLoading: boolean
  list: ApiResponseList
}

export const initialState: State = {
  list: [] as ApiResponseList,
  isLoading: false,
}

export const getReservations = createAsyncThunk('reservations/getReservations', reservationEndpoints.getAll)
export const patchReservation = createAsyncThunk('reservations/patchReservation', reservationEndpoints.patch)
export const removeReservation = createAsyncThunk('reservations/removeReservation', reservationEndpoints.remove)

const slice = createSlice({
  name: 'reservations',
  initialState,
  reducers: {},
  extraReducers: actions => {
    actions.addCase(getReservations.pending, state => {
      state.isLoading = true
    })
    actions.addCase(patchReservation.pending, state => {
      state.isLoading = true
    })
    actions.addCase(removeReservation.pending, state => {
      state.isLoading = true
    })
    // Fulfilled
    actions.addCase(getReservations.fulfilled, (state, { payload }: PayloadAction<ApiResponseList>) => {
      state.isLoading = false
      state.list = [...payload].sort((a, b) => (+new Date(b.createdAt || '') > +new Date(a.createdAt || '') ? 1 : -1))
    })
    actions.addCase(patchReservation.fulfilled, (state, { payload: _ }: PayloadAction<ConsumerReservation>) => {
      state.isLoading = false
    })
    actions.addCase(removeReservation.fulfilled, (state, { payload: _ }: PayloadAction<ConsumerReservation>) => {
      state.isLoading = false
    })
  },
})

export default slice.reducer
