import styled from 'styled-components/macro'

export const Modal = styled.div`
  position: fixed;
  z-index: 102;
  top: 12vh;
  left: 0;
  right: 0;
  margin: auto;
  width: min(670px, calc(100vw - 32px));
  background: linear-gradient(33deg, hsl(210deg 18% 30%), hsl(210deg 18% 40%));
  transition: opacity 0.25s ease-in-out;
  color: ${props => props.theme.normalText};
  border-radius: 32px;

  box-shadow: 0 4px 42px ${props => props.theme.middleShadow};
  overflow: hidden;

  &.closed {
    pointer-events: none;
    opacity: 0;
  }

  button span,
  a {
    background: linear-gradient(20deg, hsl(206deg 100% 50%), hsl(192deg 98% 52%));
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    transition: filter 0.15s ease-in-out;

    &:hover {
      filter: brightness(1.2);
    }
  }

  .business-text {
    max-width: none;
    color: #fff;
    font-family: Montserrat;
    font-weight: 500;
    text-transform: none;
    font-size: 28px;
  }

  .perk-description {
    padding: 8px 12px;
    width: 100%;
    background: linear-gradient(20deg, hsl(202deg 100% 55%), hsl(192deg 98% 50%));
    color: #fff;
    font-weight: 500;
    font-size: 17px;
  }
`

export const Header = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 24px 32px;
  padding-bottom: 20px;
  width: 100%;
`

export const ModalContent = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  color: #fff;
  padding: 30px 50px;
  padding-top: 16px;
  max-height: min(calc(100vh - 435px), 750px);
  overflow-y: auto;

  .new-tag {
    background: #ff8324;
    padding: 1px 5px;
    color: #fff;
    border-radius: 16px;
    font-size: 12px;
    font-weight: 700;
    text-transform: uppercase;
  }

  ul {
    li {
      margin: 16px 0;
      font-weight: 500;

      .description {
        max-height: 0px;
        overflow: hidden;
        padding-top: 0;
        opacity: 0.0001;
        transition: all 0.5s cubic-bezier(0.25, 1, 0.32, 1);
      }

      .image-comparison {
        width: calc(100% + 100px);
        margin: 0 -50px;
        height: 150px;
        position: relative;
        background: #0004;
        overflow: hidden;

        max-height: 0px;
        overflow: hidden;
        opacity: 0.0001;
        transition: all 0.5s cubic-bezier(0.25, 1, 0.32, 1);

        &.shown {
          max-height: 150px;
          margin-top: 16px;
          opacity: 1;

          .indicator {
            animation: moveLeft 7s ease-in-out forwards;
            animation-iteration-count: infinite;
            animation-delay: 1s;
          }

          .low-res {
            animation: slideLeftReveal 7s ease-in-out forwards;
            animation-iteration-count: infinite;
            animation-delay: 1s;
          }
        }

        .indicator {
          height: 100%;
          width: 2px;
          background: #fff;
          position: absolute;
          right: 0;
          top: 0;
          display: flex;
          justify-items: center;
          align-items: center;
          box-shadow: 4px 0px 12px #000;

          svg {
            color: ${props => props.theme.accent};
            background: #fff;
            padding: 4px;
            border-radius: 100%;
            margin-left: -11px;
          }
        }

        img {
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          object-fit: cover;
        }
      }
    }

    @keyframes slideLeftReveal {
      0% {
        clip-path: polygon(0 0, 0 100%, 100% 100%, 100% 0);
      }
      70% {
        clip-path: polygon(0 0, 0 100%, 0% 100%, 0% 0);
      }
      100% {
        clip-path: polygon(0 0, 0 100%, 0% 100%, 0% 0);
      }
    }

    @keyframes moveLeft {
      0% {
        right: 0px;
      }
      70% {
        right: 100%;
      }
      100% {
        right: 100%;
      }
    }

    &.with-descriptions {
      .title {
        font-weight: 700;
      }

      .description {
        max-height: 42px;
        padding-top: 4px;
        opacity: 1;

        @media (max-width: 768px) {
          max-height: 125px;
        }
      }
    }
  }

  button {
    align-self: flex-start;
    padding: 0;
    margin-top: 0;
    margin-top: -10px;
  }
`

export const ModalFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 55px;
  padding-right: 19px;
  padding-top: 16px;
  box-sizing: content-box;

  .price {
    background: #fff;
    padding: 6px 24px;
    padding-left: 20px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    font-size: 16px;
    border-top-right-radius: 32px;
    white-space: nowrap;
    color: #353f4b;

    &.active {
      background: linear-gradient(20deg, hsl(206deg 100% 50%), hsl(192deg 98% 52%));
      color: #fff;
    }

    .active-indicator {
      color: #fff;
      font-weight: 600;

      svg {
        margin-right: 8px;
      }
    }

    .invoicing-info-text {
      font-size: 11px;
      font-weight: 600;
      color: ${props => props.theme.accent};
    }
  }

  button {
    .icon-wrapper {
      background: transparent;
      right: 6px;
      top: 11px;
      transition: transform 0.5s cubic-bezier(0.25, 1, 0.32, 1);
    }

    :hover {
      .icon-wrapper {
        transform: translateX(4px);
      }
    }
  }

  button {
    margin-top: 0;
  }

  .next-bill-date {
    padding: 0 8px;
    color: #fff;

    @media (max-width: 768px) {
      padding-right: 0px;
      padding-left: 8px;
    }
  }
`
