import { wideFont } from 'src/global/helpers'
import styled from 'styled-components/macro'

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  border-left: 1px solid ${props => props.theme.border};
  border-right: 1px solid ${props => props.theme.border};
  cursor: pointer;
  position: relative;
  z-index: 3;
  height: 100%;

  .business-circle {
    height: 50px;
    width: 50px;
    position: absolute;
    left: 8px;
    z-index: -1;
    background-clip: padding-box;

    &.small {
      width: 46px;
      height: 46px;
      left: 8px;
    }

    &.expiring {
      filter: hue-rotate(149deg);
    }
  }

  .restaurant-logo-wrapper {
    height: 44px;
    width: 44px;
    overflow: hidden;
    z-index: 10;
    padding: 4px;
    border: 0px solid transparent;
    border-radius: 100%;
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fffb;
    margin-left: -5px;
    margin-right: 15px;
  }

  .restaurant-logo {
    width: 100%;
  }

  .username {
    ${wideFont};
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    min-width: 60px;
  }

  .header-restaurant-logo {
    width: 150px;
  }

  svg {
    margin-right: 11px;
    font-size: 20px;
    margin-bottom: 1px;
    width: 22px;
    text-align: center;

    &.small {
      font-size: 14px;
      margin-right: 4px;
    }

    &.smaller {
      font-size: 12px;
      width: 22px;
      margin-left: -4px;
    }
  }

  .business-text {
    max-width: none;
    color: #fff !important;
    font-family: Montserrat;
    font-weight: 500;
    text-transform: none;
    font-size: 20px;
  }

  .new-tag {
    display: inline-block;
    background: #ff8324;
    padding: 1px 5px;
    color: #fff;
    border-radius: 16px;
    font-size: 12px;
    font-weight: 700;
    text-transform: uppercase;
    margin-right: 3px !important;
    margin-bottom: 1px !important;
  }

  .party-mode-indicator {
    border-radius: 16px;
    font-size: 12px;
    font-weight: 700;
    text-transform: uppercase;
    color: ${props => props.theme.accent};
    display: flex;
    align-items: center;
    margin-top: 4px;
  }

  .dropdown-item-group {
    display: flex;
    color: ${props => props.theme.mutedText};

    span {
      text-align: left;
    }

    &.home {
      border-bottom: 1px solid ${props => props.theme.border};
    }

    .dropdown-item:first-of-type {
      border-right: 1px solid ${props => props.theme.border};
    }
    .dropdown-item:last-of-type {
      border-bottom: 1px solid ${props => props.theme.border};
    }

    .wrapper {
      display: flex;
    }
    .email-info {
      color: ${props => props.theme.mutedText};
      text-align: left;
    }
  }
`

export const DropdownButton = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 16px;
  position: relative;
  z-index: 3;
  min-width: 230px;
  justify-content: space-between;
  color: ${props => props.theme.mutedText};

  @media (max-width: 768px) {
    min-width: 180px;
  }

  :hover {
    background: ${props => props.theme.activeBackground};
  }
`

export const BoxShadowContainer = styled.div`
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  margin-bottom: 50px;
  overflow: hidden;
  margin-bottom: 80px;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
  background: ${props => props.theme.white};
`

export const OrderButton = styled.button<{ variant?: 'green' | 'gray' }>`
  background-color: ${props => (props.variant === 'green' ? '#11b7f3' : '#818e99')};
  background: ${props => props.theme.white};
  border-radius: 24px;
  padding: 10px 12px;
  color: ${props => props.theme.mutedText};
  width: 200px;
  border: 0;
  font-weight: 800;
  text-transform: uppercase;
  cursor: pointer;
  font-size: 12px;
  color: #11b7f3;
  display: inline-block;

  .icon {
    font-size: 14px;
    color: #11b7f3;
    margin-left: 4px;
  }

  :hover {
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.25);
  }
`

export const Dropdown = styled.div`
  position: absolute;
  right: 0;
  top: 58px;
  border-top: 1px solid ${props => props.theme.border};
`

export const DropdownItem = styled.div<{ variant?: 'green' | 'gray' }>`
  height: 60px;
  padding: 16px;
  z-index: 2;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 700;
  display: flex;
  align-items: center;
  background: ${props => props.theme.white};
  border-bottom: 1px solid ${props => props.theme.border};
  text-align: right;
  min-width: 230px;

  @media (max-width: 768px) {
    min-width: 206px;
    padding-right: 0;
  }

  @media (max-width: 413px) {
    min-width: 185px;
  }

  :not(:hover) span {
    color: ${props => props.theme.mutedText};
  }

  :hover {
    background: ${props => props.theme.activeBackground};
  }

  :last-of-type {
    border-bottom-width: 0;
  }

  &.small {
    padding: 8px 16px;
    height: 30px;
    background-color: ${props => (props.variant === 'green' ? props.theme.accent : props.theme.mutedText)};
    font-weight: 600;
    color: ${props => props.theme.white};
    text-transform: none;
    justify-content: flex-end;

    :hover {
      background-color: ${props => (props.variant === 'green' ? props.theme.accent + 'bb' : props.theme.mutedText)};
      color: ${props => props.theme.white};
    }
  }

  &[disabled] {
    opacity: 0.6;
    pointer-events: none;
    cursor: pointer;
  }

  &.business {
    background: linear-gradient(20deg, hsl(208deg 18% 30%), hsl(208deg 18% 48%));
    color: #fff;
    transition: all 0.3s cubic-bezier(0.25, 1, 0.32, 1);
    position: relative;

    &:hover {
      opacity: 0.9;
    }

    .success-animation {
      position: absolute;
      left: -6px;
      top: -7px;
      width: 74px;
      height: 74px;
      pointer-events: none;
      z-index: 10;
    }

    .img-wrapper {
      width: 42px;
      height: 42px;
      border-radius: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: opacity 0.3s ease-in;
      margin-left: -8px;
      margin-right: 4px;

      img {
        width: 28px;
      }

      &.active {
        /* border: 2.5px solid ${props => props.theme.accent}; */
        margin-left: -6px;
        margin-right: 6px;
        img {
          width: 22px;
          margin-bottom: -1px;
        }
      }
    }

    &.animating {
      .img-wrapper {
        opacity: 0;
      }

      .business-circle {
        opacity: 0;
      }
    }
  }
`
