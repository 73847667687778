import styled from 'styled-components/macro'

export const Wrapper = styled.div`
  width: 340px;
  @media (max-width: 768px) {
    width: 100%;
  }

  .reservation {
    display: flex;
    background-color: ${props => props.theme.white};
    border-top-left-radius: 14px;
    border-top-right-radius: 14px;
    font-size: 16px;
    padding: 8px;
    justify-content: space-between;
    flex-direction: row;
    z-index: 2;
    font-weight: 500;
    box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.1);
    position: relative;
  }

  .action-buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 16px 8px;
    padding-bottom: 12px;
    background: ${props => props.theme.white};
    position: relative;
    border-bottom-left-radius: 14px;
    border-bottom-right-radius: 14px;

    button {
      margin: 0;

      &[data-variant='as-text'] {
        color: ${props => props.theme.darkRed};
        padding: 0 16px;
      }
    }
  }

  img {
    width: 16px;
  }

  .reservation-number {
    background-color: ${props => props.theme.darkRed};
    border-radius: 8px;
    border-top-left-radius: 0;
    border-bottom-right-radius: 0px;
    padding: 6px 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    color: ${props => props.theme.white};
    position: absolute;
    top: 0px;
    right: 0px;
    min-width: 70px;
  }

  .delete-button {
    display: none;
    text-decoration: underline;
  }

  .reservation:hover {
    .delete-button {
      cursor: pointer;
      display: block;
    }

    .reservation-number-text {
      display: none;
    }
  }

  .left-section {
    justify-content: center;
    padding-left: 4px;
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  a {
    color: ${props => props.theme.darkRed};
  }

  .action-title {
    color: ${props => props.theme.darkRed};
    font-weight: 600;
  }

  .bullet {
    width: 7px;
    height: 7px;
    background-color: #9ae963;
    border-radius: 7px;
    margin-left: 3px;
    margin-right: 5px;
  }

  .information-row {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-radius: 8px;
    padding: 8px 12px;
    padding-right: 24px;
    margin-left: 0;
    background-color: ${props => props.theme.darkRed};
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: -16px;
    padding-top: 24px;

    &.disabled {
      background-color: ${props => props.theme.disabledRedHue};
    }

    &.white {
      border-top: 1px solid ${props => props.theme.border};
      border-top-right-radius: 0;
      border-top-left-radius: 0;
      z-index: 0;
      background: ${props => props.theme.white};
    }

    svg {
      color: ${props => props.theme.white};
    }
  }

  .with-continuation {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    box-shadow: 0px 8px 12px 0px rgba(0, 0, 0, 0.1);
    background-color: ${props => props.theme.white};
    z-index: 2;
    position: relative;
  }

  .extra-comments-text {
    font-size: 15px;
    text-shadow: 0px 0px 3px #fff;
    font-weight: 600;
  }

  .confirmed-text {
    font-size: 14px;
    font-weight: 600;
    color: ${props => props.theme.white};
    padding-right: 4px;
  }

  .actions-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0px 16px;
  }

  .center-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 6px;

    span,
    p {
      word-break: break-all;
    }
  }

  .boldish {
    font-weight: 600;
  }

  .light {
    font-weight: 500;
  }

  .bold {
    font-weight: 800;
  }
`
