import React, { useEffect, useState } from 'react'
import { userEndpoints } from 'src/api'
import { Wrapper } from './styles'
import moment from 'moment'
import 'moment/locale/ro' // without this line it didn't work
import { isBusiness } from '@meniudigital/shared'
import { useSelector } from 'react-redux'
import { usersSelector } from 'src/state/selectors'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChartSimple, faLock } from '@fortawesome/free-solid-svg-icons'
import GoogleAd from 'src/global/GoogleAd'
moment.locale('ro')

type StatsDateEntry = {
  date: string
  value: string
}

type Stats = {
  today: string
  yesterday: string
  daysAgo7: string
  daysAgo30: string
  last7DaysGraph: StatsDateEntry[]
}

type Props = {
  showsBanner: boolean
}

const mockStats = {
  today: '213',
  yesterday: '185',
  daysAgo7: '793',
  daysAgo30: '3914',
  last7DaysGraph: [
    { date: moment().subtract(6, 'days').format('YYYYMMDD'), value: '101' },
    { date: moment().subtract(5, 'days').format('YYYYMMDD'), value: '153' },
    { date: moment().subtract(4, 'days').format('YYYYMMDD'), value: '98' },
    { date: moment().subtract(3, 'days').format('YYYYMMDD'), value: '145' },
    { date: moment().subtract(2, 'days').format('YYYYMMDD'), value: '182' },
    { date: moment().subtract(1, 'days').format('YYYYMMDD'), value: '137' },
    { date: moment().format('YYYYMMDD'), value: '232' },
  ],
}

export default function StatsPanel({ showsBanner }: Props) {
  const [realStats, setRealStats] = useState<Stats | undefined>()
  const { user } = useSelector(usersSelector)

  useEffect(() => {
    if (isBusiness(user) && !realStats) {
      getMenuStats()
    }
  }, [user])

  const getMenuStats = async () => {
    setRealStats(await userEndpoints.getMyStats())
  }

  const stats = (isBusiness(user) ? realStats : mockStats)!

  if (!stats) {
    return null
  }

  const maxValue = Math.max(...stats.last7DaysGraph.map(x => +x.value))

  const last7Days = []

  for (let i = 6; i >= 0; i--) {
    let date = moment()
    date.subtract(i, 'day')

    const value = stats.last7DaysGraph.find(x => x.date === date.format('YYYYMMDD'))?.value
    last7Days.push({ date, value: value || 0 })
  }

  return (
    <Wrapper
      style={{ top: showsBanner ? '118px' : '82px' }}
      onClick={() => !isBusiness(user) && window.openBusinessModal('stats')}
    >
      <label>
        <FontAwesomeIcon icon={faChartSimple} /> Accesări meniu{' '}
        {!isBusiness(user) && <FontAwesomeIcon className="icon" icon={faLock} />}
      </label>
      {!isBusiness(user) && (
        <div className="business-button">
          <img src="/logo-white.svg" />

          <div className="brand-texts">
            <div className="brand-name">MeniuDigital</div>
            Business
          </div>
        </div>
      )}
      <div className={`content ${isBusiness(user) ? '' : 'non-business'} `}>
        {isBusiness(user) && (
          <>
            <div className="stat">
              <div className="label">Astăzi</div>
              <div className="value">{stats.today}</div>
            </div>
            <div className="stat">
              <div className="label">Ieri</div>
              <div className="value">{stats.yesterday}</div>
            </div>
            <div className="stat">
              <div className="label">Ultimele 7 zile</div>
              <div className="value">{stats.daysAgo7}</div>
            </div>
            <div className="stat">
              <div className="label">Ultimele 30 de zile</div>
              <div className="value">{stats.daysAgo30}</div>
            </div>
          </>
        )}
        <div className="graph">
          {last7Days.map(x => (
            <div
              key={x.date.toString()}
              className="bar"
              style={{ height: `${(+x.value / maxValue) * 100}%`, opacity: +x.value > 0 ? 1 : 0.5 }}
            >
              <div className="value">{x.value}</div>
              <span>{x.date.format('dd')}</span>
            </div>
          ))}
        </div>
      </div>

      {!isBusiness(user) && !user?.isSuperAdmin && (
        <>
          <div style={{ height: '8px' }}></div>
          <GoogleAd id="3236929706" />
        </>
      )}
    </Wrapper>
  )
}
