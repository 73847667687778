import styled from 'styled-components/macro'

export const Wrapper = styled.div<{ width?: string }>`
  position: relative;
  margin-bottom: 24px;
  padding: 16px 24px;
  padding-right: 68px;
  width: min(720px, calc(100vw - 32px));
  box-shadow: 0 4px 12px ${props => props.theme.shadow};

  .close-button {
    top: 17px;
  }

  p {
    line-height: 22px;
  }

  .floating-emoji {
    font-size: 40px;
    position: fixed;
    right: 15vw;
    top: 59px;
    animation: oscillate 2.5s ease infinite;
    pointer-events: none;
    z-index: 200;
  }

  svg {
    margin-right: 8px;
    color: ${props => props.theme.accent};
  }

  @keyframes oscillate {
    0% {
      transform: rotate(-70deg) translateX(0px);
    }
    50% {
      transform: rotate(-70deg) translateX(-15px);
    }
    100% {
      transform: rotate(-70deg) translateX(0px);
    }
  }
`
