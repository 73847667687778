import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { State } from 'src/state'
import { patchCurrentUser } from 'src/state/users'
import Toggle from 'react-toggle'
import { UserPatchType } from '@meniudigital/shared'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'

export default function PartyModeSetting() {
  const dispatch = useDispatch()
  const { user } = useSelector((state: State) => state.users)

  if (!user) {
    return null
  }

  const toggleIsUsingPartyMode = async () => {
    try {
      if (!user.isUsingPartyMode) {
        await window.showModal({
          title: `Activare câmp "Preț Eveniment"`,
          text: `Ești sigur că dorești afișarea câmpului "Preț Eveniment" pe produse?`,
          confirmButtonText: 'Da, afișează',
          cancelButtonText: 'Nu',
        })
      }

      const payload = {
        type: UserPatchType.IsUsingPartyMode,
        data: !user.isUsingPartyMode,
      }
      dispatch(patchCurrentUser(payload as any))
    } catch (e) {}
  }

  const togglePartyMode = async () => {
    try {
      if (!user.inPartyMode) {
        await window.showModal({
          title: `Activare Mod "Eveniment"`,
          text: `Ești sigur că dorești activarea modului "Eveniment"?`,
          confirmButtonText: 'Da, activează',
          cancelButtonText: 'Nu',
        })
      }

      const payload = {
        type: UserPatchType.InPartyMode,
        data: !user.inPartyMode,
      }
      dispatch(patchCurrentUser(payload as any))
    } catch (e) {}
  }

  return (
    <div className="setting-group">
      <div className="setting-explainer" style={{ marginTop: 0, marginBottom: 32 }}>
        <FontAwesomeIcon icon={faInfoCircle} /> Modul "Eveniment" îți permite să afișezi un preț alternativ al tuturor produselor
        tale cu un singur click (pentru atunci când ai un eveniment special de exemplu). <br />
        Dacă dorești să-l folosești, vei avea un câmp nou la editarea produselor, "Preț Eveniment".
      </div>
      <div className="setting-explainer" style={{ marginTop: 0 }}>
        {user?.isUsingPartyMode
          ? 'Poți edita prețurile de eveniment ale produselor'
          : 'Dorești să poți edita câmpul de "Preț Eveniment" de pe produse?'}
      </div>
      <div className="setting">
        <div className="setting-name">
          <div className="setting-icon">✏️</div>
          <span>Afișează câmpul "Preț Eveniment"</span>
        </div>
        <div className="right-side">
          <span className="only-desktop">{user?.isUsingPartyMode ? 'Vizibil' : 'Ascuns'}</span>
          <Toggle className="toggle" checked={user?.isUsingPartyMode} icons={false} onChange={toggleIsUsingPartyMode} />
        </div>
      </div>

      <div className="setting-explainer">
        {user?.inPartyMode
          ? 'Se afișează clienților prețurile din câmpul "Preț Eveniment"'
          : 'Momentan se afișează clienților prețul normal al produselor tale'}
      </div>
      <div className="setting">
        <div className="setting-name">
          <div className="setting-icon">
            <img src="/icons/party.png?v=2" alt="Party icon" />
          </div>
          <span>Activează Modul "Eveniment"</span>
          {user?.inPartyMode && <div className="bullet" />}
        </div>
        <div className="right-side">
          <span className="only-desktop">{user?.inPartyMode ? 'Activ' : 'Inactiv'}</span>
          <Toggle className="toggle" checked={user?.inPartyMode} icons={false} onChange={togglePartyMode} />
        </div>
      </div>
    </div>
  )
}
